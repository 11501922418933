<template>
  <v-container class="print-body" ref="reportElem">
    <v-row>
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">
          <strong>Admin Settings</strong>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="text-h5">Calculate Company size settings</span>
          </v-card-title>
          <v-card-text v-if="loadingData">
            <span>Loading</span>
            <v-progress-linear indeterminate></v-progress-linear>
          </v-card-text>
          <v-card-text v-if="!loadingData">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="settings.companySizeSmallThreshold"
                      :rules="thrasholdRules"
                      label="Company Size Small Threshold"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="settings.companySizeBigThreshold"
                      :rules="thrasholdRules"
                      label="Company Size Large Threshold"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="settings.weeksForCalculation"
                      :rules="thrasholdRules"
                      label="Weeks For Calculation"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              block
              :loading="sendingData"
              color="primary"
              @click="saveSettings"
              :disabled="!valid || sendingData"
            >
              Save
            </v-btn>
          </v-card-actions>
          <v-alert
            v-if="!success"
            type="error"
            class="mx-4"
            :value="!success"
            transition="scale-transition"
            dismissible
          >
            <span>Something went wrong. Please try again later.</span>
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  name: "AdminSettings",
  data() {
    return {
      valid: true,
      settings: {
        companySizeSmallThreshold: 1000,
        companySizeBigThreshold: 2200,
        weeksForCalculation: 52,
      },
      loadingData: true,
      sendingData: false,
      success: true,
      thrasholdRules: [
        (v) => !!v || "Is required",
        (v) => v >= 0 || "Must be greater than 0",
        (v) => v <= 1000000 || "Must be less than 1000000",
        (v) => !isNaN(v) || "Must be a number",
      ],
    };
  },
  methods: {
    saveSettings() {
      console.log(this.settings);
      this.sendingData = true;
      console.log(this.sendingData);
      this.$refs.form.validate();
      if (this.valid) {
        axios
          .post(
            this.$store.state.apiUrl + "/api/admin/setAdminSettings",
            this.settings
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            this.success = false;
          });
      }
      this.sendingData = false;
    },
  },
  mounted: async function () {
    const res = await axios.get(
      this.$store.state.apiUrl + "/api/admin/getAdminSettings"
    );
    this.settings.companySizeBigThreshold = Number(
      res.data.adminSettings.companySizeBigThreshold
    );
    this.loadingData = false;
  },
};
</script>
